<template>
    <div id="modalLoyalty" class="row food-custom-modal">
        <b-modal id="modalLoyalty"
                 ref="modalLoyalty"
                 centered
                 :size="modalSize"
                 no-close-on-backdrop
                 no-close-on-esc
                 hide-header-close
                 header-text-variant="light"
                 header-class="modal-header-custom"
                 v-model="modalShow"
                 class="food-custom-modal"
                 :title="$t('loyatyModalTitle')">

            <div class="loyalty-modal">
                <p class="loyalty-modal-explain">{{$t("loyaltyModalText")}}</p>
                <p class="loyalty-modal-balance-text">{{$t("loyaltyModalBalanceText")}}</p>
                <p class="loyalty-modal-balance">{{formatPrice(loyaltyBalance)}}</p>
                <p class="loyalty-modal-balance-action-text">{{$t("loyaltyModalUseLoyaltyText")}}</p>
                <div class="loyalty-actions">
                    <div class="icon-moins icon product-minus-icon" @click="handleMinus"></div>
                    <span class="mx-2 loyalty-modal-usage">{{formatPrice(loyaltyToUse)}}</span>
                    <div class="icon-plus icon product-plus-icon" @click="handlePlus"></div>
                </div>
            </div>
            <div slot="modal-footer" class="modal-actions">
                <button class="btn nok" @click="handleNo">{{$t("loyaltyModalNoBtnLabel")}}</button>
                <button class="btn ok" @click="handleOk">{{$t("loyaltyModalOkBtnLabel")}}</button>
            </div>


        </b-modal>
    </div>
</template>

<script>

    import { formatPrice } from "@/assets/js/Utils"
    const Basket = require("@/assets/js/Food/Basket");

    export default {
        name: "ModalLoyalty",
        data () {
            return {
                modalShow: true,
                modalSize: "lg",
                loyaltyToUse: 0,
                loyaltyBalance: parseFloat(localStorage.getItem("IEC_LOYALTY")) || 45,
                basket: Basket.getLocalBasket()
            }
        },
        props: {
            propName: {
                type: Number
            },
        },
        mounted(){
            this.loyaltyToUse = parseInt(this.basket.totalAmount * 0.5)
        },
        methods: {
            formatPrice,
            handlePlus(){
                let newLoyaltyToUse = this.loyaltyToUse + 1
                if(newLoyaltyToUse < this.basket.totalAmount){
                    this.loyaltyToUse = newLoyaltyToUse
                }
            },
            handleMinus(){
                let newLoyaltyToUse = this.loyaltyToUse - 1
                if(newLoyaltyToUse >= 0){
                    this.loyaltyToUse = newLoyaltyToUse
                }

            },
            handleOk () {
                localStorage.setItem("IEC_LOYALTY_TO_USE", this.loyaltyToUse)
                localStorage.setItem("IEC_LOYALTY", this.loyaltyBalance)
                this.toPaymentMethod()
            },
            handleNo(){
                localStorage.setItem("IEC_LOYALTY", this.loyaltyBalance)
                this.modalShow = false
                this.loyaltyToUse = 0
                this.toPaymentMethod()
            },
            toPaymentMethod(){
                Basket.updateBasket(newBasket => {
                    newBasket.loyaltyToUse = this.loyaltyToUse
                    newBasket.basketProducts.forEach(element => {
                        if(element.product.ean === '5449000214911'){
                            let nbProductPromos = parseInt(element.qty / 2)
                            let nbProductNormalPrice = element.qty - nbProductPromos
                            newBasket.totalAmount -= element.product.price / 2 * nbProductPromos
                        }
                    });
                    localStorage.setItem("IEC_basket", JSON.stringify(newBasket));
                    this.$root.$emit('basketChgt');
                    this.$router.push('/paymentMethods');
                })
            },
        }
    }
</script>
<style scoped>
    .loyalty-modal{
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
    }
    .loyalty-modal-explain{
        font-weight: bold;
        text-align: center;
    }
    .loyalty-modal-balance{
        font-weight: bold;
        font-size: 1.8rem;
    }
    .loyalty-modal-balance-action-text{
        text-align: center;
    }
    .loyalty-modal-usage{
        font-size: 1.3rem;
    }
    .loyalty-actions{
        display: flex;
        align-items: center;
    }

</style>
