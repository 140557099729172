<template>
    <div class="camera-wrapper">
        <div :style="{top: `${headerHeight}px`}" class="simulate-scan" @click="$emit('simulateScan')"></div>
        <div v-if="addingLoader" class="loader">
            <half-circle-spinner :animation-duration="1000" :size="32" color="#ff1d5e" />
            <span class="loader-text">{{$t("productAdded")}}</span>
        </div>
        <div class="camera-overlay" ref="cameraOverlay">
            <canvas id="canvas" hidden></canvas>
        </div>
        <video v-show="loaded" id="video" ref="barcode" class="cameraCustom"></video>
    </div>
</template>
<script>
    import { HalfCircleSpinner } from 'epic-spinners'
    export default {
        props: {
            headerHeight: {
                type: Number
            },
        },
        components:{
            HalfCircleSpinner
        },
        data() {
            return {
                addingLoader:false,
                loaded: false
            }
        },
        mounted() {
            this.$refs.barcode.onloadeddata = this.drawOverlay
            
            window.addEventListener('resize', this.drawOverlay, false);
        },
        methods: {
            drawOverlay() {
                this.loaded = false
                let canvas = document.getElementById("canvas")
                let ctx = canvas.getContext("2d")
                ctx.canvas.height = this.$refs.cameraOverlay.clientHeight
                ctx.canvas.width = this.$refs.cameraOverlay.clientWidth
                let rectWidth = this.$refs.barcode.videoWidth / 2
                let rectHeight = this.$refs.barcode.videoHeight / 3
                if(this.$refs.barcode.clientWidth > this.$refs.barcode.videoWidth){
                    rectWidth = this.$refs.barcode.clientWidth / 2
                    rectHeight = this.$refs.barcode.clientHeight / 3
                }
                let rectCoordinates = {
                    x: (ctx.canvas.width - rectWidth) / 2,
                    y: (ctx.canvas.height - rectHeight) / 2
                }

                ctx.beginPath()
                ctx.moveTo(0, 0)
                ctx.lineTo(canvas.width, 0)
                ctx.lineTo(canvas.width,  rectCoordinates.y + rectHeight)
                ctx.lineTo(rectCoordinates.x + rectWidth, rectCoordinates.y + rectHeight)
                ctx.lineTo(rectCoordinates.x + rectWidth, rectCoordinates.y )
                ctx.lineTo(rectCoordinates.x , rectCoordinates.y)
                ctx.lineTo(rectCoordinates.x , rectCoordinates.y + rectHeight)
                ctx.lineTo(rectCoordinates.x + rectWidth , rectCoordinates.y + rectHeight)
                ctx.lineTo(canvas.width , rectCoordinates.y + rectHeight)
                ctx.lineTo(canvas.width , canvas.height)
                ctx.lineTo(0 , canvas.height)
                ctx.closePath()
                ctx.fillStyle = "rgba(0,0,0,0.5)"
                ctx.lineWidth = 2
                ctx.fill();
                ctx.strokeStyle = "white"
                ctx.strokeRect(rectCoordinates.x, rectCoordinates.y, rectWidth, rectHeight)
                let cameraOverlay = this.$refs.cameraOverlay
                //cameraOverlay.setAttribute("style", `background-image:url("${canvas.toDataURL("data:image/svg")}")`)
                this.loaded = true
            }
        },
        beforeDestroy: function () {
            window.removeEventListener('resize', this.drawOverlay, false);
        }
    }
</script>
<style scoped>
    .simulate-scan{
        height: 48px;
        width: 100%;
        position: absolute;
        z-index: 1000;
    }   
</style>